import React from "react";
import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import {Currency} from "../Utils";

interface Props {
  name: string;
  amountInMinor: number;
  currency: string;
  reference: string;
}

export const BeneficiaryDetails: React.FC<Props> = ({name, amountInMinor, currency, reference}) => {
  return (
    <Card variant="outlined" style={{backgroundColor: 'rgb(232,241,201)'}}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Typography align="center" variant="h3">You are paying to</Typography>
            <Typography align="center" variant="subtitle1">{name}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography align="center" variant="h3">Amount</Typography>
            <Typography align="center" variant="subtitle1"><Currency amount={amountInMinor} currency={currency} /></Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography align="center" variant="h3">Reference</Typography>
            <Typography align="center" variant="subtitle1">{reference}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
