import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {Box, Card, CardContent, Container, Grid, Typography} from "@material-ui/core";
import {DevButton} from "@tl-prisma/core";
import MockBankHeader from "../MockBankHeader";
import BeneficiaryDetails from "../BeneficiaryDetails";
import SingleImmediatePayment from "../SingleImmediatePayments/SingleImmediatePayment";
import singleImmediatePaymentService from "../SingleImmediatePayments/SingleImmediatePaymentsService";
import LoadingPanel from "../LoadingPanel";


export const MockBankDecoupled: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {id} = useParams<{ id: string }>();
  const [singleImmediatePayment, setSingleImmediatePayment] = useState<SingleImmediatePayment | null>();
  const {hash, state} = useLocation();
  const history = useHistory();

  const getToken = (): string | null => {
    const matches = hash.match(/#token=([^=/]+)$/);
    return matches && matches.length > 1 ? matches[1] : null;
  }

  const getFromState = (key: string) => {
    return state ? (state as any)[key] : null;
  }

  const navigateToErrorPage = (): void => {
    history.push('/error');
  }

  const refresh = (): void => {
    history.go(0);
  }

  const token = getToken();

  useEffect(() => {
    const statePayment = getFromState('payment');
    if (statePayment) {
      setSingleImmediatePayment(statePayment);
      setIsLoading(false);
    } else {
      singleImmediatePaymentService.getPayment(id, token).then((result) => {
        if (!result) {
          navigateToErrorPage();
        }
        setSingleImmediatePayment(result);
        setIsLoading(false);
      }).catch(() => {
        navigateToErrorPage();
      });
    }
  }, []);

  const payment: SingleImmediatePayment = singleImmediatePayment!;

  const getBeneficiaryReference = (): string => {
    switch (payment.references.type) {
      case 'single':
        return payment.references.reference;
      case 'separate':
        return payment.references.beneficiary_reference;
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item sm={1} md={2} lg={3}/>
        <Grid item sm={10} md={8} lg={6}>
          {isLoading
            ? <LoadingPanel/>
            :
            <>
              <Box pt={8} pb={2}>
                <MockBankHeader name={payment.provider_ui_display_name}/>
              </Box>
              <Card variant="outlined">
                <Box p={5}>
                  <CardContent>
                    <Typography variant="h2" paragraph={true}></Typography>
                    <Box pb={3}>
                      <BeneficiaryDetails
                        name={payment.beneficiary_name}
                        amountInMinor={payment.amount}
                        currency={payment.currency}
                        reference={getBeneficiaryReference()}/>
                    </Box>

                    <Box pb={3}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="h1">You are done here! 👏🏻 </Typography>
                          <Typography variant="subtitle1" paragraph={true}>You can now close this window and come back
                            to your merchant.</Typography>
                          <Typography variant="subtitle1" paragraph={true}>By the way, your payment is
                            currently <b>{payment.status}</b>.</Typography>
                        </CardContent>
                      </Card>
                    </Box>
                    <Box pt={10}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <DevButton id="refresh" type="secondary" label="Refresh" onClick={refresh}/>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </>
          }
        </Grid>
        <Grid item sm={1} md={2} lg={3}/>
      </Grid>
    </Container>
  );
}
